























































































import Vue from "vue";
import gql from "graphql-tag";
import Drivetrain from "./Drivetrain";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";
import TabsFormSection from "@/components/TabsFormSection.vue";
import Tab from "@/components/form/Tab.vue";

export default Vue.extend({
  apollo: {
    pdb_enginetypes_by_pk: {
      // GraphQL Query
      query: gql`
        query pdb_enginetypes_by_pk($id: bigint!) {
          pdb_enginetypes_by_pk(id: $id) {
            id
            drive_id
            drive {
              name
              type
            }
            gear_id
            gear {
              name
              type
            }
            engine_id
            engine {
              name
            }
            technical_specifications_links {
              value
              technicalSpecificationByTechnicalSpecification {
                name
                label
                attributeByAttribute {
                  unit
                  name
                  attribute_type {
                    input_type
                    validation_rules
                  }
                }
              }
            }
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          id: this.$route.params.drivetrainID,
        };
      },
      skip() {
        // Skip request if no model id is provided in creation mode
        return this.skipQuery;
      },
      update(data) {
        return data.pdb_enginetypes_by_pk;
      },
      result({ data, loading }) {
        if (!loading) {
          console.log(data);
          this.pdb_enginetype = data.pdb_enginetypes_by_pk;
        }
      },
    },
    pdb_categories: {
      query: gql`
        query pdb_categories {
          pdb_categories(
            where: { category_type: { _eq: "technical_description" } }
          ) {
            id
            name
          }
        }
      `,
      update(data) {
        console.log(data);

        return data.pdb_categories;
      },
    },
    pdb_technical_specifications: {
      query: gql`
        query pdb_technical_specifications {
          pdb_technical_specifications {
            name
            label
            attributeByAttribute {
              name
              unit
              attribute_type {
                input_type
                validation_rules
              }
            }
            category {
              id
              name
            }
          }
        }
      `,
      update(data) {
        console.log(data);

        return data.pdb_technical_specifications;
      },
    },
    pdb_engines: {
      query: gql`
        query pdb_engines {
          pdb_engines {
            id
            name
          }
        }
      `,
      update(data) {
        return data.pdb_engines;
      },
    },
    pdb_drives: {
      query: gql`
        query pdb_drives {
          pdb_drives {
            id
            name
          }
        }
      `,
      update(data) {
        return data.pdb_drives;
      },
    },
    pdb_gears: {
      query: gql`
        query pdb_gears {
          pdb_gears {
            id
            name
          }
        }
      `,
      update(data) {
        return data.pdb_gears;
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_enginetype: {} as Drivetrain,
    };
  },
  computed: {
    selectedEngineID: {
      get(): string {
        return `${this.pdb_enginetype.engine_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_enginetype.engine_id = newID;
      },
    },
    selectedDriveID: {
      get(): string {
        return `${this.pdb_enginetype.drive_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_enginetype.drive_id = newID;
      },
    },
    selectedGearID: {
      get(): string {
        return `${this.pdb_enginetype.gear_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_enginetype.gear_id = newID;
      },
    },
  },
  created() {
    // Get model if model id is provided
    if (this.$route.params.trimlevelID) {
      this.skipQuery = false;
    }
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      const objectToStore: Drivetrain = Object.assign({}, this.pdb_enginetype);
      delete objectToStore.__typename;

      if (!this.$route.params.modeltypeID) {
        // Create new model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($objects: [pdb_modells_insert_input!]!) {
                insert_pdb_modells(objects: $objects) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              objects: objectToStore,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$router.push("/models");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      } else {
        // Update model
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation(
                $set: pdb_modells_set_input
                $pk: pdb_modells_pk_columns_input!
              ) {
                update_pdb_modells_by_pk(_set: $set, pk_columns: $pk) {
                  id
                }
              }
            `,
            // Parameters
            variables: {
              set: objectToStore,
              pk: { id: objectToStore.id },
            },
          })
          .then((data) => {
            // Result
            this.$router.push("/modeltypes");
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    cancel() {
      this.$router.push("/trimlevels");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
    TabsFormSection,
    Tab,
  },
});
