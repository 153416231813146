































































import Vue from "vue";
import gql from "graphql-tag";
import OnlineIndicator from "@/components/OnlineIndicator.vue";
import Headline from "@/components/Headline.vue";
import Head from "@/components/table/Head.vue";
import DefaultCell from "@/components/table/DefaultCell.vue";
import ImageThumb from "@/components/ImageThumb.vue";
import PrimaryButton from "@/components/PrimaryButton.vue";
import LinkAction from "@/components/LinkAction.vue";
import PaginationLink from "@/components/table/PaginationLink.vue";
import draggable from "vuedraggable";

const limit = 10;

export default Vue.extend({
  data() {
    return {
      searchTerm: "" as string,
      offset: 0 as number,
    };
  },
  computed: {
    pages(): number {
      return this.pdb_enginetypes_aggregate &&
        this.pdb_enginetypes_aggregate.aggregate.count
        ? Math.ceil(this.pdb_enginetypes_aggregate.aggregate.count / limit)
        : 0;
    },
  },
  apollo: {
    pdb_enginetypes: {
      query: gql`
        query pdb_enginetypes(
          $gqlSearchTerm: String
          $limit: Int!
          $offset: Int!
        ) {
          pdb_enginetypes(
            order_by: { order: desc }
            where: { name: { _ilike: $gqlSearchTerm } }
            limit: $limit
            offset: $offset
          ) {
            id
            enginelinks {
              id
              trimlevel {
                name
                modeltype {
                  name
                }
              }
            }
            name
            drive {
              name
            }
            engine {
              name
            }
            gear {
              name
            }
          }
        }
      `,
      variables() {
        return {
          gqlSearchTerm: `%${this.searchTerm}%`,
          offset: 0,
          limit,
        };
      },
    },
    pdb_enginetypes_aggregate: {
      query: gql`
        query pdb_enginetypes_aggregate {
          pdb_enginetypes_aggregate {
            aggregate {
              count
            }
          }
        }
      `,
    },
  },
  methods: {
    onSortableEnd() {
      console.log("sort end");
    },
    next() {
      this.offset = this.offset + limit;
      this.$apollo.queries.pdb_enginetypes.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
          limit,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // const newTags = pdb_modells.tagsPage.tags
          // const hasMore = fetchMoreResult.tagsPage.hasMore
          console.log(fetchMoreResult);
          console.log(previousResult);

          return fetchMoreResult;
        },
      });
    },
    prev() {
      this.offset = this.offset - limit;
      this.$apollo.queries.pdb_enginetypes.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
          limit,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // const newTags = pdb_modells.tagsPage.tags
          // const hasMore = fetchMoreResult.tagsPage.hasMore
          console.log(fetchMoreResult);
          console.log(previousResult);

          return fetchMoreResult;
        },
      });
    },
    jumpTo(page: number) {
      this.offset = (page - 1) * limit;
      this.$apollo.queries.pdb_enginetypes.fetchMore({
        // New variables
        variables: {
          offset: this.offset,
          limit,
        },
        // Transform the previous result with new data
        updateQuery: (previousResult, { fetchMoreResult }) => {
          // const newTags = pdb_modells.tagsPage.tags
          // const hasMore = fetchMoreResult.tagsPage.hasMore
          console.log(fetchMoreResult);
          console.log(previousResult);

          return fetchMoreResult;
        },
      });
    },
    remove(enginetypeID: number) {
      if (window.confirm("Willst du wirklich löschen?")) {
        this.$apollo
          .mutate({
            // Query
            mutation: gql`
              mutation($enginetypeID: bigint) {
                delete_pdb_enginetypes(where: { id: { _eq: $enginetypeID } }) {
                  affected_rows
                }
              }
            `,
            // Parameters
            variables: {
              enginetypeID: enginetypeID,
            },
          })
          .then((data) => {
            // Result
            console.log(data);
            this.$apollo.queries.pdb_enginetypes.refetch();
          })
          .catch((error) => {
            // Error
            console.error(error);
            // We restore the initial user input
          });
      }
    },
    create() {
      console.log("create new drivetrain");
      this.$router.push("/drivetrains/form");
    },
    edit(enginetypeID: number) {
      this.$router.push("/drivetrains/assign/trimlevels/" + enginetypeID);
    },
  },
  created() {
    this.$apollo.queries.pdb_enginetypes.refetch();
  },
  components: {
    OnlineIndicator,
    Headline,
    Head,
    DefaultCell,
    ImageThumb,
    PrimaryButton,
    draggable,
    PaginationLink,
    LinkAction,
  },
});
