





























































































import Vue from "vue";
import gql from "graphql-tag";
import EquipmentFeaturesLink from "./EquipmentFeaturesLink";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";
import TabsFormSection from "@/components/TabsFormSection.vue";
import Tab from "@/components/form/Tab.vue";
import Head from "@/components/table/Head.vue";
import DefaultCell from "@/components/table/DefaultCell.vue";
import LinkAction from "@/components/LinkAction.vue";

export default Vue.extend({
  apollo: {
    pdb_equipment_featureslinks: {
      // GraphQL Query
      query: gql`
        query pdb_equipment_featureslinks($enginelinkID: bigint!) {
          pdb_equipment_featureslinks(
            where: { enginelink_id: { _eq: $enginelinkID } }
          ) {
            created
            enginelink_id
            id
            highlight
            price
            order
            equipment_id
            disclaimer {
              name
            }
            equipment_feature {
              name
              category {
                name
              }
            }
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          enginelinkID: this.$route.params.enginelinkID,
        };
      },
      update(data) {
        console.log(data);

        return data.pdb_equipment_featureslinks;
      },
    },
    pdb_disclaimers: {
      query: gql`
        query pdb_disclaimers {
          pdb_disclaimers {
            id
            name
          }
        }
      `,
      update(data) {
        console.log(data);
        if (data.pdb_disclaimers[0].id) {
          const emptyVal = { id: null, name: "Kein Disclaimer" };
          data.pdb_disclaimers.unshift(emptyVal);
        }
        return data.pdb_disclaimers;
      },
    },
    pdb_equipment_features: {
      query: gql`
        query pdb_equipment_features {
          pdb_equipment_features {
            id
            name
          }
        }
      `,
      update(data) {
        return data.pdb_equipment_features;
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_equipment_featureslink: {} as EquipmentFeaturesLink,
    };
  },
  computed: {
    selectedEquipmentID: {
      get(): string {
        return `${this.pdb_equipment_featureslink.equipment_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_equipment_featureslink.equipment_id = newID;
      },
    },
    selectedDisclaimerID: {
      get(): string {
        return `${this.pdb_equipment_featureslink.disclaimer_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_equipment_featureslink.disclaimer_id = newID;
      },
    },
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      console.log("store");

      const objectToStore: EquipmentFeaturesLink = Object.assign(
        {},
        this.pdb_equipment_featureslink
      );
      delete objectToStore.__typename;
      objectToStore.enginelink_id = parseInt(this.$route.params.enginelinkID);
      console.log(objectToStore);

      // Create new model
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($objects: [pdb_equipment_featureslinks_insert_input!]!) {
              insert_pdb_equipment_featureslinks(objects: $objects) {
                affected_rows
              }
            }
          `,
          // Parameters
          variables: {
            objects: objectToStore,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_equipment_featureslinks.refetch();
          this.pdb_equipment_featureslink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    remove(equipmentlinkID: number) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($id: bigint!) {
              delete_pdb_equipment_featureslinks_by_pk(id: $id) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            id: equipmentlinkID,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_equipment_featureslinks.refetch();
          this.pdb_enginelink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    cancel() {
      this.$router.push("/equipment-features/assign");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
    TabsFormSection,
    Tab,
    Head,
    DefaultCell,
    LinkAction,
  },
});
