



























































import Vue from "vue";
import gql from "graphql-tag";
import Enginelink from "./Enginelink";
import Headline from "@/components/Headline.vue";
import FormSection from "@/components/FormSection.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import CheckboxInput from "@/components/form/CheckboxInput.vue";
import UploadInput from "@/components/form/UploadInput.vue";
import SaveBar from "@/components/form/SaveBar.vue";
import SingleSelectInput from "@/components/form/SingleSelectInput.vue";
import TabsFormSection from "@/components/TabsFormSection.vue";
import Tab from "@/components/form/Tab.vue";
import Head from "@/components/table/Head.vue";
import DefaultCell from "@/components/table/DefaultCell.vue";
import LinkAction from "@/components/LinkAction.vue";

export default Vue.extend({
  apollo: {
    pdb_enginelinks: {
      // GraphQL Query
      query: gql`
        query pdb_enginelinks($enginetypeID: bigint!) {
          pdb_enginelinks(where: { enginetype_id: { _eq: $enginetypeID } }) {
            created
            enginetype_id
            id
            insurance_group
            trimlevel {
              name
              modeltype {
                name
              }
            }
          }
        }
      `,
      // Reactive variables
      variables() {
        return {
          enginetypeID: this.$route.params.enginetypeID,
        };
      },
      update(data) {
        return data.pdb_enginelinks;
      },
    },
    pdb_categories: {
      query: gql`
        query pdb_categories {
          pdb_categories(
            where: { category_type: { _eq: "technical_description" } }
          ) {
            id
            name
          }
        }
      `,
      update(data) {
        console.log(data);

        return data.pdb_categories;
      },
    },
    pdb_trimlevels: {
      query: gql`
        query pdb_trimlevels {
          pdb_trimlevels {
            id
            name
            modeltype {
              name
            }
          }
        }
      `,
      update(data) {
        const transformedList = data.pdb_trimlevels.map((trim: any) => {
          return { id: trim.id, name: trim.modeltype.name + ` - ` + trim.name };
        });
        console.log(transformedList);

        return transformedList;
      },
    },
  },
  data() {
    return {
      skipQuery: true,
      pdb_enginelink: {} as Enginelink,
    };
  },
  computed: {
    selectedTrimlevelID: {
      get(): string {
        return `${this.pdb_enginelink.trimlevel_id}`;
      },
      set(newVal: string) {
        const newID = parseInt(newVal);
        this.pdb_enginelink.trimlevel_id = newID;
      },
    },
  },
  methods: {
    store() {
      // clone current model object and remove __typename property which was added by apollo
      console.log("store");

      const objectToStore: Enginelink = Object.assign({}, this.pdb_enginelink);
      delete objectToStore.__typename;
      objectToStore.enginetype_id = parseInt(this.$route.params.enginetypeID);
      console.log(objectToStore);

      // Create new model
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($objects: [pdb_enginelinks_insert_input!]!) {
              insert_pdb_enginelinks(objects: $objects) {
                affected_rows
              }
            }
          `,
          // Parameters
          variables: {
            objects: objectToStore,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_enginelinks.refetch();
          this.pdb_enginelink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    remove(enginelinkID: number) {
      this.$apollo
        .mutate({
          // Query
          mutation: gql`
            mutation($id: bigint!) {
              delete_pdb_enginelinks_by_pk(id: $id) {
                id
              }
            }
          `,
          // Parameters
          variables: {
            id: enginelinkID,
          },
        })
        .then((data) => {
          // Result
          console.log(data);
          // this.$router.push("/");
          this.$apollo.queries.pdb_enginelinks.refetch();
          this.pdb_enginelink = {};
        })
        .catch((error) => {
          // Error
          console.error(error);
          // We restore the initial user input
        });
    },
    cancel() {
      this.$router.push("/drivetrains/assign/trimlevels");
    },
  },
  components: {
    Headline,
    FormSection,
    TextInput,
    CheckboxInput,
    UploadInput,
    SaveBar,
    SingleSelectInput,
    NumberInput,
    TabsFormSection,
    Tab,
    Head,
    DefaultCell,
    LinkAction,
  },
});
